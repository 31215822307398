import { ReportTypes } from "./types";

const initialState = {
  daily: null,
  weekly: null,
  safety: null,
  training: null,
};

export const reportReducer = (state = initialState, action) => {
  // console.log("TYPE", action?.type);
  switch (action.type) {
    case ReportTypes.DAILY + "_SUCCESS":
      return {
        ...state,
        daily: action.payload.data,
      };
    case ReportTypes.WEEKLY + "_SUCCESS":
      return {
        ...state,
        weekly: action.payload.data,
      };
    case ReportTypes.SAFETY + "_SUCCESS":
      return {
        ...state,
        safety: action.payload.data,
      };
    case ReportTypes.REPORTS_TRAINING + "_SUCCESS":
      return {
        ...state,
        training: action.payload.data,
      };

    default:
      return {
        ...state,
      };
  }
};
