import AreaManagerLayout from "../../layouts/areaManager";
import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAllUserTypes } from "src/redux/userManagement/actions";
import { useNavigate } from "react-router-dom";

const AreaManagemerPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getAllUserTypes());
  }, [dispatch]);

  const onClickAddNewManager = useCallback(()=>{
    navigate('/areaManagers/create')
  },[navigate])

  return <AreaManagerLayout 
  onClickAddNewManager={onClickAddNewManager}
  />;
};
export default AreaManagemerPage;
