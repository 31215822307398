import ReactApexChart from "react-apexcharts";
import React, { useCallback, useMemo, useState } from 'react'
import { ApexOptions } from "apexcharts";
import { ReportType } from "src/interfaces/reports";
import { Box, Button, Typography } from "@mui/material";
import ReportDetailsComponent from "../layouts/reports/reportDetails";
interface BarChartProps {
    title: string;
    values?: ReportType;
    getColor?: (val: number) => void;
  }
const BarChart = ({
    title,
    values,
}:BarChartProps)=> {

    const [open,setOpen] = useState<boolean>(false);


    const onClickDetails = useCallback(()=>{
      setOpen(true)
    },[])
    const handleClose = useCallback(()=>{
      setOpen(false)
    },[])
    const series = useMemo<ApexOptions['series']>(()=>{
        return [{
            name : 'Yes',
            data:[values?.yes_percentage],
            color:'#00e396'
        },{
            name:'No',
            data:[values?.no_percentage],
            color:'#feb219'
        },{
            name:'Not Applicable',
            data:[values?.not_attended_percentage],
            color:'#ff4561'
        },{
            name:'Not Applicable',
            data:[values?.not_applicable_percentage],
            color:'#948789'
        }] as ApexOptions['series'];
    },[values])

    const options = useMemo<ApexOptions>(()=>{
        return {
            grid:{
                show:false,
            },
            chart:{
                type: 'bar',
                stacked: true,
                width:'100%',
                stackType: '100%',
                toolbar:{
                    show:false
                },
            },
            plotOptions: {
                bar: {
                  horizontal: true,
                },
              },
              
              stroke: {
                width: 1,
                colors: ['#fff']
              },
              title: {
                text: undefined,
                floating:false,

              },
              xaxis: {
                categories: [''],
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return val + "%"
                  }
                }
              },
              fill: {
                opacity: 1
              },
              legend: {
                show:false
              }
            }as ApexOptions
    },[])

    return (
        <Box
        sx={{
          border:'2px solid #f1f1f1',
          padding:'16px',
          borderRadius:'4px',
          margin:'4px',
        }}
      >
        <Typography color="text.secondary" fontWeight={"700"}
          marginBottom={2}
        >
          {title}
        </Typography>
        <ReactApexChart 
            height={'100px'}
            type="bar"
            series={series}
            options={options}
        />
         {(values?.yes_percentage && values?.yes_percentage > 0) ? (
        <Button
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
          }}
          variant="outlined"
          onClick={onClickDetails}
        >
          Details
        </Button>
      ):<></>}
      <ReportDetailsComponent open={open} handleClose={handleClose} values={values} title={title}/>

        </Box>
    )
}
export default BarChart;