import React, { useCallback } from "react";

import {
  Box,
  Card,
  Container,
  Typography,
  useTheme,
  Button,
} from "@mui/material";

import { MixTheme } from "@styles";
import TextInput from "../../components/TextInput";
import PasswordInput from "../../components/PasswordInput";
import { LoginType } from "src/interfaces/login";
import { SnackbarProps } from "src/interfaces/snackbar";
import TyfhuSnack from "../../components/TyfhuSnack";

interface LoginProps {
  onClickLogin: () => void;
  data: LoginType;
  errors: LoginType | undefined;
  setValues: (value: string, key: string) => void;
  handleClose: () => void;
  snackbar: SnackbarProps;
}

const LoginLayout = ({
  onClickLogin,
  data,
  errors,
  setValues,
  handleClose,
  snackbar,
}: LoginProps) => {
  const theme = useTheme<MixTheme>();

  const onChange = useCallback(
    (val: string, key?: string) => {
      if (key) {
        setValues(val, key);
      }
    },
    [setValues]
  );

  return (
    <Box
      sx={{
        backgroundColor: theme.palette?.background?.default,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        minHeight: "100vh",
      }}
    >
      <Container maxWidth={"sm"}>
        <Card
          sx={{
            width: "100%",
            backgroundColor: "#FFF",
            padding: "32px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            fontWeight={"700"}
            fontSize={"28px"}
            fontFamily={theme.font?.tertiary}
            color={theme.palette?.text?.primary}
            textAlign={"center"}
          >
            Tyfhu Admin
          </Typography>
          <Typography
            fontWeight={"700"}
            fontSize={"20px"}
            fontFamily={theme.font?.tertiary}
            color={theme.palette?.text?.secondary}
          >
            Login
          </Typography>
          <TextInput
            label={"Username"}
            value={data?.username}
            error={errors?.username}
            field={"username"}
            onChange={onChange}
          />
          <PasswordInput
            label={"Password"}
            value={data?.password}
            field={"password"}
            error={errors?.password}
            onChange={onChange}
          />
          <Button
            variant="contained"
            sx={{
              margin: "16px 0px 16px 0px",
            }}
            onClick={onClickLogin}
          >
            Login
          </Button>
        </Card>
      </Container>

      <TyfhuSnack snackbar={snackbar} handleClose={handleClose} />
    </Box>
  );
};
export default LoginLayout;
