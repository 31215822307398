import CreateCompanyLayout from "../../../layouts/admin/companies/createCompany.layout";
import { useFormik } from "formik";
import React, { useCallback,useState } from "react";
import { useDispatch } from "react-redux";
import { CreateCompanyForm,CompanyResult } from "src/interfaces/company";
import * as Yup from "yup";
import { createCompany } from "src/redux/companies/actions";

const schema = Yup.object().shape({
  company_name: Yup.string().required("Enter company name"),
  company_country: Yup.string().required("Select a country"),
  no_of_branches: Yup.number().required("Enter number of branches"),
  restaurant_type: Yup.string().required("Select restaurant type"),
  email: Yup.string().email().required("Please enter email"),
});
const CreateCompanyPage = () => {
  const dispatch = useDispatch();
  
  const { values, handleChange, errors, isValid,resetForm, validateForm } = useFormik({
    initialValues: {} as CreateCompanyForm,
    validationSchema: schema,
    onSubmit: () => undefined,
  });
  const [result,setResult] = useState<CompanyResult>();

  const onPressCreate = useCallback(async() => {
    validateForm()
    setResult(undefined)
    if (isValid) {
      const data:any = await dispatch(createCompany(values))
      setResult(data?.data)
      resetForm()
    }
  }, [values, isValid,resetForm,dispatch,validateForm]);

  return (
    <CreateCompanyLayout
      values={values}
      errors={errors}
      handleChange={handleChange}
      onPressCreate={onPressCreate}
      result={result}
    />
  );
};
export default CreateCompanyPage;
