import React, { useMemo, useCallback } from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import ManagerIcon from "@mui/icons-material/ManageAccounts";
import StoreIcon from "@mui/icons-material/Storefront";
import QuestionsIcon from "@mui/icons-material/QuestionMarkOutlined";
import LogoutIcon from "@mui/icons-material/LogoutOutlined";

import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import { MixTheme } from "@styles";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ROLES } from "src/constants/roles";
import StorageService from "src/storage";
import { getUserData } from "src/redux/user/actions";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}
interface DrawerItems {
  title: string;
  icon: JSX.Element;
  selectedIcon: JSX.Element;
  to: string;
  subPages?: string[];
  onlyAdmin: boolean;
  isLogout?: boolean;
}
const Drawers: DrawerItems[] = [
  {
    title: "Home",
    icon: <HomeIcon />,
    selectedIcon: <HomeIcon style={{ color: "white" }} />,
    to: "/",
    onlyAdmin: false,
  },
  {
    title: "Companies",
    icon: <StoreIcon />,
    selectedIcon: <ManagerIcon style={{ color: "white" }} />,
    to: "/companies",
    subPages: ["/companies", "/companies/create"],
    onlyAdmin: false,
  },
  {
    title: "Area Managers",
    icon: <ManagerIcon />,
    selectedIcon: <ManagerIcon style={{ color: "white" }} />,
    to: "/areaManagers",
    subPages: [
      "/areaManagers",
      "/areaManagers/create",
      "/areaManagers/edit",
      "/areaManagers/reports",
    ],
    onlyAdmin: true,
  },
  {
    title: "Questions",
    icon: <QuestionsIcon />,
    selectedIcon: <QuestionsIcon style={{ color: "white" }} />,
    to: "/questions",
    subPages: ["/questions", "/questions/edit"],
    onlyAdmin: true,
  },
  {
    title: "Settings",
    icon: <SettingsIcon />,
    selectedIcon: <SettingsIcon style={{ color: "white" }} />,
    to: "/settings",
    onlyAdmin: false,
  },
  {
    title: "Logout",
    icon: <LogoutIcon />,
    selectedIcon: <LogoutIcon style={{ color: "white" }} />,
    isLogout: true,
    to: "/logout",
    onlyAdmin: false,
  },
];
const drawerWidth = 240;

const AdminDrawer = (props: Props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const theme = useTheme<MixTheme>();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.user);

  const selected = useMemo(() => {
    return Drawers.find(
      (item) =>
        item.to === location.pathname ||
        item?.subPages?.includes(location.pathname)
    )?.title;
  }, [location]);

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  },[mobileOpen]);

  const onClickLogout = useCallback(async () => {
    StorageService.token.clearAll();
    await dispatch(getUserData());
    navigate("/");
  }, [dispatch,navigate]);

  const drawer = useMemo(() => {
    return (
      <div>
        <Toolbar
          sx={{
            backgroundColor: theme.palette.text?.primary,
          }}
        >
          <Typography
            fontWeight={"700"}
            fontSize={"20px"}
            fontFamily={theme.font?.tertiary}
            color={"#FFF"}
            textAlign={"center"}
          >
            Tyfhu Admin
          </Typography>
        </Toolbar>
        <Divider />
        <List>
          {Drawers.map((item: DrawerItems) => {
            const show = item.onlyAdmin ? user.role === ROLES.ADMIN : true;
            if (show)
              return (
                <ListItem
                  key={item.title}
                  disablePadding
                  onClick={() => {
                    if (item?.isLogout) {
                      onClickLogout();
                    } else {
                      navigate(item.to);
                    }
                    handleDrawerToggle();
                  }}
                  sx={{
                    backgroundColor:
                      selected === item.title
                        ? theme.palette.text.primary
                        : "#fff",
                    color:
                      selected === item.title
                        ? "#fff"
                        : theme.palette.text.secondary,
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      {selected === item.title ? item.selectedIcon : item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.title} />
                  </ListItemButton>
                </ListItem>
              );
              return (
                <></>
              )
          })}
        </List>
      </div>
    );
  }, [theme, user, selected,handleDrawerToggle,navigate,onClickLogout]);
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: theme.palette.text?.primary,
        }}
      >
        <Toolbar
          sx={{
            backgroundColor: theme.palette.text?.primary,
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {selected}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default AdminDrawer;
