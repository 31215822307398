import React,{useMemo,useRef,useCallback} from "react";
import {
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  ListSubheader,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Button
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import { ReportType } from "src/interfaces/reports";
import jsPDF from 'jspdf';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface ReportProps {
  open: boolean;
  handleClose: () => void;
  values?: ReportType;
  title: string;
}
const ReportDetailsComponent = ({
  handleClose,
  open,
  values,
  title,
}: ReportProps) => {
	const reportTemplateRef = useRef<HTMLElement>();

    const questions = useMemo(()=>{
        return values?.noAnswersList.slice(0,10) ?? []
    },[values])

    const handleGeneratePdf = useCallback(() => {
      // Adding the fonts.
      // doc.setFont('Inter-Regular', 'normal');

      if(reportTemplateRef.current) {

        const doc = new jsPDF({
          unit: 'px',
          format: [reportTemplateRef.current?.clientWidth, reportTemplateRef.current?.clientHeight+50],
          orientation:"l"
          
        });
    
        doc.html(reportTemplateRef.current, {
          async callback(doc) {
            await doc.save('document');
          },
        });

      }
    },[reportTemplateRef]);

  return (
    <Dialog
      fullScreen={true}
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        padding: "0",
        margin: "0",
      }}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Box
        sx={{
          width: "50vw",
        }}
      >
        <Box  ref={reportTemplateRef}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <List
            subheader={

                <ListSubheader component={'h1'} 
                    sx={{
                        color:'text.secondary',
                        fontWeight:'700',
                    }}
                >
                {'Top 10 Issues'}
            </ListSubheader>
            }
        >
          {questions.map((item,index) => {
            return <ListItem>
                <ListItemText
                    primary={`${index+1} . ${item.question}`}
                    primaryTypographyProps={{
                        color:'text.secondary'
                    }}
                >
                </ListItemText>
                <Typography>
                {`${item.count} occasions`}
                </Typography>
            </ListItem>;
          })}
        </List>
        </Box>
        <Button onClick={handleGeneratePdf} variant={'outlined'}
          sx={{
            margin:2
          }}
        >Download</Button>
      </Box>
    </Dialog>
  );
};
export default ReportDetailsComponent;
