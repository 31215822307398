import React from "react";
import TrainingLayout, { TrainingForm } from "./TrainingLayout";
import CompanyDetails from "./CompanyDetails";

interface SettingsProps {
  internalTraining?: string;
  onTrainingSubmit: (values: TrainingForm) => void;
}

const CompanyAdminSettingsLayout = ({
  internalTraining,
  onTrainingSubmit,
}: SettingsProps) => {
  return (
    <>
      <CompanyDetails />
      <TrainingLayout
        internalTraining={internalTraining}
        onTrainingSubmit={onTrainingSubmit}
      />
    </>
  );
};
export default CompanyAdminSettingsLayout;
