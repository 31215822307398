import {  Box, Button, } from "@mui/material";
import React from "react";
import ManagerListLayout from "./managers";

interface Props {
  onClickAddNewManager:()=>void
}
const AreaManagerLayout = ({onClickAddNewManager}: Props) => {

  return (
    <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
      <Box
        sx={{
          justifyContent:'flex-end',
          display:'flex',
          narginBottom:'10px',
          padding:'16px'
        }}
      >
      <Button
        variant="contained"
        sx={{
          alignSelf:'flex-end'
        }}
        onClick={onClickAddNewManager}
      >
        Add Area Manager
      </Button>
      </Box>
      <ManagerListLayout />
    </Box>
  );
};
export default AreaManagerLayout;
