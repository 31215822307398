import CompanyAdminSettingsLayout from "src/layouts/companyAdmin/settings/settings.layout";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHttpClient } from "src/services/rest-api";
import { HTTPService } from "src/services/httpservice";
import { getUserData } from "src/redux/user/actions";
import { TrainingForm } from "src/layouts/companyAdmin/settings/TrainingLayout";

const CompanyAdminSettings = () => {
  const user = useSelector((state: any) => state.user.user);

  const dispatch = useDispatch()

  const onTrainingSubmit = useCallback(async (values: TrainingForm)=>{
    try {
        await getHttpClient(HTTPService.UPDATE_INTERNAL_TRAINING,'put',{
            ...values
        })
        dispatch(getUserData())
    }catch(e){
        // TODO e
    }
  },[dispatch])

  return (
    <CompanyAdminSettingsLayout internalTraining={user?.internalTraining} onTrainingSubmit={onTrainingSubmit}/>
  );
};
export default CompanyAdminSettings;
