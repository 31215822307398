import React from "react";
import TextInput from "src/components/TextInput";
import { Box, Button, Card, Typography } from "@mui/material";
import { useFormik } from "formik";

import * as Yup from "yup";
export interface TrainingForm {
  internalTraining: string;
}
interface TrainingProps {
  internalTraining?: string;
  onTrainingSubmit: (values: TrainingForm) => void;
}

const TrainingSchema = Yup.object().shape({
  internalTraining: Yup.string()
    .url("Please enter valid URL")
    .required("Please enter internal training URL"),
});
const TrainingLayout = ({
  internalTraining,
  onTrainingSubmit,
}: TrainingProps) => {
  const { values, handleChange, handleSubmit, errors } = useFormik({
    initialValues: {
      internalTraining: internalTraining ?? "",
    },
    onSubmit: onTrainingSubmit,
    validationSchema: TrainingSchema,
  });

  return (
    <>
      <Card
        sx={{
          padding: "32px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography color={"black"} variant="h4" fontWeight={"600"}>
          Training
        </Typography>
        <Box display={"flex"} alignItems={"center"}>
          <TextInput
            label="Training URL"
            field="internalTraining"
            sx={{
              width: "50%",
            }}
            value={values?.internalTraining}
            error={errors?.internalTraining}
            onChange={handleChange("internalTraining")}
          />

          <Button
            variant="contained"
            sx={{
              marginLeft: "8px",
              height: "35px",
            }}
            onClick={() => handleSubmit()}
          >
            {`Save`}
          </Button>
        </Box>
      </Card>
    </>
  );
};

export default TrainingLayout;
