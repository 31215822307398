import CreateAreaManagerLayout from "../../layouts/areaManager/createAreaManager";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  createAreaManager,
  getAllUserTypes,
  updateAreaManager,
} from "src/redux/userManagement/actions";
import { useDispatch } from "react-redux";
import { CreateManagerFormProps } from "src/interfaces/login";
import { useLocation, useNavigate } from "react-router-dom";

const CreateAreaManagerPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedStores, setSelectedStores] = useState<string[]>([]);
  const [data, setData] = useState<CreateManagerFormProps>({
    manager_name: "",
    shop_region: "",
  });
  const [errors, setErrors] = useState<CreateManagerFormProps>({
    manager_name: "",
    shop_region: "",
  });

  const update = useMemo(()=>Boolean(location?.state?.item),[location])

  useEffect(() => {
    if (location.state?.item) {
      setData({
        manager_name: location.state?.item?.manager_name,
        shop_region: location.state?.item?.shop_region,
      });
      if (location.state?.item?.stores) {
        setSelectedStores(
          location.state?.item?.stores.map((item: any) => item.user_uuid)
        );
      }
    }
  }, [location]);

  useEffect(() => {
    dispatch(getAllUserTypes());
  }, [dispatch]);
  const setValues = useCallback((value: string, key: string) => {
    setData((data) => {
      return {
        ...data,
        [key]: value,
      };
    });
  }, []);
  const validate = useCallback(() => {
    const errors: CreateManagerFormProps | any = {};
    if (data.manager_name.trim().length < 4) {
      errors.manager_name = "Enter valid manager name";
    }
    if (data.shop_region.trim().length < 4) {
      errors.shop_region = "Enter valid shop region";
    }

    if (Object.keys(errors).length === 0) return true;
    throw errors;
  }, [data]);

  const onPressSelect = useCallback(
    (id: string, value: boolean) => {
      const users = [...selectedStores];
      if (!users.includes(id)) {
        users.push(id);
      } else {
        const index = users.indexOf(id);
        users.splice(index, 1);
      }
      setSelectedStores(users);
    },
    [selectedStores]
  );

  const onPressCreate = useCallback(async () => {
    try {
      validate();
      const payload = {
        ...data,
        stores: selectedStores,
        manager_uuid:update?location?.state?.item?.user_uuid:null
      };
      await dispatch(update?updateAreaManager(payload):createAreaManager(payload));
      navigate("/areamanagers");
    } catch (e) {
      setErrors(e as CreateManagerFormProps);
    }
  }, [validate, data, dispatch, navigate, selectedStores,update,location]);

  return (
    <CreateAreaManagerLayout
      update={update}
      selectedStores={selectedStores}
      onPressSelect={onPressSelect}
      data={data}
      setValues={setValues}
      errors={errors}
      onPressCreate={onPressCreate}
    />
  );
};
export default CreateAreaManagerPage;
