import React from "react";
import { Card, Typography, Box } from "@mui/material";
import StoreIcon from "@mui/icons-material/Store";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

interface DashboardProps {
  count:number;
  onClickCompanies: ()=>void;
}

const AdminDashbaordLayout = ({count,onClickCompanies}:DashboardProps) => {

  return (
    <Box>
      <Card
      onClick={onClickCompanies}
        sx={{
          padding: "32px",
          marginTop: "32px",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          justifyTracks: "center",
          width: "50%",
        }}
      >
        <StoreIcon
          fontSize="large"
          sx={{
            color: "text.primary",
            fontSize: "100px",
          }}
        />
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          sx={{
            margin:'0px 16px'
          }}
        >
          <Typography fontWeight={"bold"} fontSize={"40px"}>
            {count}
          </Typography>
          <Typography color="text.secondary" fontWeight={"700"}>
            {"Companies registered"}
          </Typography>
        </Box>
        <ArrowForwardIcon
          fontSize="large"
          sx={{
            color: "text.secondary",
          }}
        />
      </Card>
    </Box>
  );
};

export default AdminDashbaordLayout;
