import React, { useMemo,useCallback,useState } from "react";
import { ReportType } from "src/interfaces/reports";
import { Box, Button, Typography } from "@mui/material";
import ReportDetailsComponent from "../layouts/reports/reportDetails";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

interface DoughChartProps {
  title: string;
  values?: ReportType;
  getColor?: (val: number) => void;
}

const DoughChart = ({ title, values, getColor }: DoughChartProps) => {
  const [open,setOpen] = useState<boolean>(false);

  const onClickDetails = useCallback(()=>{
    setOpen(true)
  },[])
  const handleClose = useCallback(()=>{
    setOpen(false)
  },[])
  const series = useMemo<ApexOptions['series']>(()=>{
    return [values?.yes_percentage ?? 0, values?.no_percentage ?? 0,values?.not_attended_percentage ?? 0, values?.not_applicable_percentage ?? 0] as ApexOptions['series'];
},[values])
const options = useMemo<ApexOptions>(()=>{
  return {
      chart: {
        type: 'donut',
      },

      plotOptions: {
        pie: {
          customScale:1,
          donut:{
            labels:{
              show:true,
              
            }
          }
        }
      },
      labels:['Yes','No',"Not Attended","Not Applicable"],
      colors:['#00e396','#feb219','#ff4561','#948789'],
      grid: {
        padding: {
          
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val +''
          }
        }
      },
      legend:{
        show:false
      }
      
    } as ApexOptions
},[])

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        border:'2px solid #f1f1f1',
        padding:'16px',
        borderRadius:'4px',
      margin:'4px',
        height:'100%'
      }}
    >
      <Typography color="text.secondary" fontWeight={"700"}
        marginBottom={2}
      >
        {title}
      </Typography>
      
      <ReactApexChart 
            type="donut"
            series={series}
            options={options}
        />
      {/* {(values?.yes_percentage && values?.yes_percentage > 0) ? ( */}
        <Button
          sx={{
            alignSelf: "center",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop:2
          }}
          variant="outlined"
          onClick={onClickDetails}
        >
          Details
        </Button>
      {/* ):<></>} */}
      <ReportDetailsComponent open={open} handleClose={handleClose} values={values} title={title}/>

    </Box>
  );
};
export default DoughChart;
