import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getDailyReports,
  getSafetyReports,
  getTrainingReports,
  getWeeklyReports,
} from "src/redux/reports/actions";
import { GraphDataProps } from "src/interfaces/dashboard";
import moment from "moment";
import StoreReportsLayout from "../../layouts/stores/storeReports.layout";

const AreaReportsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [dataSelector, setDataSelector] = useState<GraphDataProps>({
    date_selector: "today",
    from_date: moment().format("YYYY-MM-DD").toString(),
    to_date: moment().format("YYYY-MM-DD").toString(),
  });

  const dailyReports = useSelector((state: any) => state.reports.daily);
  const weeklyReports = useSelector((state: any) => state.reports.weekly);
  const safetyReports = useSelector((state: any) => state.reports.safety);
  const trainingReports = useSelector((state: any) => state.reports.training);
  useEffect(() => {
    if (location?.state?.area_manager_id) {
      const payload = {
        to_date: dataSelector.to_date,
        from_date: dataSelector.from_date,
        area_manager_id: location?.state?.area_manager_id,
      };
      dispatch(getDailyReports(payload));
      dispatch(getWeeklyReports(payload));
      dispatch(getSafetyReports(payload));
      dispatch(getTrainingReports(payload));
    } else {
      navigate("/stores");
    }
  }, [location, dataSelector, dispatch, navigate]);

  const onChangeDateSelector = useCallback(
    (val: string) => {
      const today = moment().format("YYYY-MM-DD").toString();
      const fromDate =
        val === "today"
          ? today
          : val === "weekly"
          ? moment().subtract({ days: 7 }).format("YYYY-MM-DD").toString()
          : val === "monthly"
          ? moment().set({ date: 1 }).format("YYYY-MM-DD").toString()
          : val === "custom"
          ? dataSelector.from_date
          : today;

      setDataSelector({
        ...dataSelector,
        date_selector: val,
        from_date: fromDate,
        to_date: today,
      });
    },
    [dataSelector]
  );

  const onChangeDropdownValue = useCallback(
    (key: string, val: string) => {
      setDataSelector({
        ...dataSelector,
        [key]: val,
      });
    },
    [dataSelector]
  );

  return (
    <StoreReportsLayout
      name={location?.state?.manager_name}
      dataSelector={dataSelector}
      onChangeDateSelector={onChangeDateSelector}
      onChangeDropdownValue={onChangeDropdownValue}
      dailyReports={dailyReports}
      safetyReports={safetyReports}
      weeklyReports={weeklyReports}
      trainingReports={trainingReports}
    />
  );
};
export default AreaReportsPage;
