import { FormikErrors } from "formik";
import React, { useMemo } from "react";
import { CompanyResult, CreateCompanyForm } from "src/interfaces/company";
import { Button, Typography, Card, Box } from "@mui/material";
import TextInput from "../../../components/TextInput";
import Dropdown, { DropdownOptions } from "../../../components/Dropdown";
import { Country } from "country-state-city";

const RestaurentTypes: DropdownOptions[] = [
  {
    value: "pz",
    label: "Pizza",
  },
  {
    value: "rs",
    label: "Restaurant",
  },
  {
    value: "cf",
    label: "Coffee",
  },
  {
    value: "pb",
    label: "Pub",
  },
  {
    value: "bky",
    label: "Bakey",
  },
  {
    value: "cky",
    label: "Chicken",
  },
  {
    value: "sh",
    label: "Sushi",
  },
];

interface CreateCompanyProps {
  values: CreateCompanyForm;
  errors?: FormikErrors<CreateCompanyForm>;
  handleChange: any;
  onPressCreate: () => void;
  result?: CompanyResult;
}
const CreateCompanyLayout = ({
  handleChange,
  values,
  errors,
  onPressCreate,
  result,
}: CreateCompanyProps) => {
  const countries = useMemo<DropdownOptions[]>(() => {
    const countryList = Country.getAllCountries();
    return countryList.map((item) => {
      return {
        label: item.name,
        value: item.isoCode,
      } as DropdownOptions;
    });
  }, []);
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
    >
      <Card
        sx={{
          padding: "32px",
          display: "flex",
          flexDirection: "column",
          width: { xs: "80%", md: "60%" },
        }}
      >
        <Typography component={"h1"} fontWeight={"700"} fontSize={"28px"}>
          {"Create Company"}
        </Typography>
        <TextInput
          label="Company Name"
          field="company_name"
          sx={{
            margin: "8px",
          }}
          value={values?.company_name}
          error={errors?.company_name}
          onChange={handleChange("company_name")}
        />
        
        <TextInput
          label="Email"
          field="email"
          sx={{
            margin: "8px",
          }}
          value={values?.email}
          error={errors?.email}
          type="email"
          onChange={handleChange("email")}
        />
        <TextInput
          label="No of branches"
          field="no_of_branches"
          sx={{
            margin: "8px",
          }}
          value={values?.no_of_branches}
          error={errors?.no_of_branches}
          onChange={handleChange("no_of_branches")}
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
          type="number"
        />
        <Dropdown
          title="Restaurant type"
          options={RestaurentTypes}
          fullWidth
          value={values?.restaurant_type}
          error={errors?.restaurant_type}
          onChange={handleChange("restaurant_type")}
          sx={{
            margin: "8px",
          }}
        />
        <Dropdown
          title="Country"
          options={countries}
          fullWidth
          value={values?.company_country}
          error={errors?.company_country}
          onChange={handleChange("company_country")}
          sx={{
            margin: "8px",
          }}
        />

        <Box>
          <Button
            variant="contained"
            sx={{
              alignSelf: "flex-end",
              margin: "8px",
            }}
            onClick={onPressCreate}
          >
            {`Create company`}
          </Button>
        </Box>
      </Card>
      {result && (
        <Card
          sx={{
            padding: "32px",
            display: "flex",
            flexDirection: "column",
            width: { xs: "80%", md: "60%" },
            marginTop: "32px",
          }}
        >
          <Typography component={"h1"} fontWeight={"700"} fontSize={"28px"}>
            {"Company created successfully"}
          </Typography>
          <Typography
            component={"h1"}
            fontWeight={"400"}
            color={"text.secondary"}
          >
            {`You can Admin with ${result?.admin}  using ${result?.adminPassword}`}
          </Typography>
          <Typography
            component={"h1"}
            fontWeight={"400"}
            color={"text.secondary"}
          >
            {`You can access stores from ${result?.firstUsername} - ${result?.lastUsername} using ${result?.storePassword}`}
          </Typography>
        </Card>
      )}
    </Box>
  );
};
export default CreateCompanyLayout;
