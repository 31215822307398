import * as React from "react";
import Box from "@mui/material/Box";
import { Card, Grid, Typography } from "@mui/material";
import DoughChart from "../../components/DoughChart";
import { GraphDataProps } from "src/interfaces/dashboard";
import GraphFilter from "../dashboard/components/GraphFilters";
import {
  DailyReports,
  SafetyReports,
  TrainingReports,
  WeeklyReports,
} from "src/interfaces/reports";

import GaugeChart from "../../components/GaugeChart";
import BarChart from "../../components/BarChart";
import TrainingChart from "../../components/TrainingChart";
interface DashboardLayoutProps {
  dataSelector: GraphDataProps;
  onChangeDateSelector: (val: string) => void;
  dailyReports?: DailyReports;
  weeklyReports?: WeeklyReports;
  safetyReports?: SafetyReports;
  onChangeDropdownValue: (key: string, val: string) => void;
  name: string;
  trainingReports?: TrainingReports;
}

export default function StoreReportsLayout({
  dataSelector,
  onChangeDateSelector,
  onChangeDropdownValue,
  dailyReports,
  safetyReports,
  weeklyReports,
  name,
  trainingReports,
}: DashboardLayoutProps) {
  return (
    <Box>
      {/* <Toolbar /> */}
      <GraphFilter
        dataSelector={dataSelector}
        onChangeDateSelector={onChangeDateSelector}
        onChangeDropdownValue={onChangeDropdownValue}
      />
      <Card
        sx={{
          padding: "16px",
          marginTop: "16px",
        }}
      >
        <Typography
          color={"text.secondary"}
          fontWeight={"700"}
          fontSize={"20"}
          margin={"12px"}
        >
          {name}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <GaugeChart
              title="Critical Issues"
              values={dailyReports?.criticalIssues}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DoughChart
              title="Effeciency Report"
              values={dailyReports?.workFlowResults}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <TrainingChart title="Training Report" values={trainingReports} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <BarChart
              title="Cleaning Issues"
              values={dailyReports?.cleaningResults}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <BarChart
              title="Food safety Issues"
              values={safetyReports?.foodSafety}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <BarChart
              title="Health and safety Issues"
              values={safetyReports?.healthAndSafety}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <BarChart
              title="Company standards"
              values={safetyReports?.companyStandards}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <BarChart
              title="Weekly cleaning Issues"
              values={weeklyReports?.weeklyCleaning}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <BarChart
              title="Weekly Health and safety Issues"
              values={weeklyReports?.weeklyHealthAndSafety}
            />
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}
