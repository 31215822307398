import ReactApexChart from "react-apexcharts";
import React, { useMemo } from "react";
import { ApexOptions } from "apexcharts";
import { TrainingReports } from "src/interfaces/reports";
import { Box, Typography } from "@mui/material";
interface BarChartProps {
  title: string;
  values?: TrainingReports;
  getColor?: (val: number) => void;
}
const TrainingChart = ({ title, values }: BarChartProps) => {
  const series = useMemo<ApexOptions["series"]>(() => {
    return [
      {
        name: "series",
        data: [
          values?.employeeCount ?? 0,
          values?.trainingCompletedCount ?? 0,
          values?.fsCompletedCount ?? 0,
          values?.hsCompletedCount ?? 0,
          values?.fireCompletedCount ?? 0,
        ],
      },
    ] as ApexOptions["series"];
  }, [values]);

  const options = useMemo<ApexOptions>(() => {
    return {
      chart: {
        id: "basic-bar",
        type: "bar",
        toolbar: {
          show: false,
        },
      },

      xaxis: {
        categories: [
          "Employee Count",
          "Training completed",
          "FS Completed",
          "HS Completed",
          "Fire safety Completed",
        ],
      },
    } as ApexOptions;
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        border: "2px solid #f1f1f1",
        padding: "16px",
        borderRadius: "4px",
        margin: "4px",
        height: "100%",
      }}
    >
      <Typography color="text.secondary" fontWeight={"700"} marginBottom={2}>
        {title}
      </Typography>
      <ReactApexChart
        type="bar"
        series={series}
        options={options}
        width={"100%"}
        height={"100%"}
      />
    </Box>
  );
};
export default TrainingChart;
